<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="30%"
    class="dialog"
    center
  >
    <div class="contents">
      <div class="child">{{ `累计扣款金额：${formData.total}元` }}</div>
      <div class="child">{{ `城市仓扣款：${formData.city}元` }}</div>
      <div class="child">{{ `供应商扣款：${formData.supplier}元` }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit" :loading="loadingSubmit"
        >确认扣款</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { HEAD_TYPE_ENUM } from "../../utils/enum/index";
import { postQcRefundDeductionDone } from "@/api/general/operation-center/index";

export default {
  name: "DeductDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loadingSubmit: false,
      titleDialog: "是否确定扣款？",
      formData: {
        total: 0, // 累计扣款
        city: 0, // 城市仓扣款
        supplier: 0, // 供应商扣款
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      selectIds: [], // 当前选中的数据ID
    };
  },
  methods: {
    /**
     * 初始化
     */
    async onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      data && this.setEditData(data);
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dialogVisible = false;
    },
    /**
     * 回显数据
     */
    setEditData(data) {
      this.selectIds = [];
      let city = 0;
      let supplier = 0;
      let total = 0;
      data.forEach((child) => {
        // 城市仓
        if (child.head_type === HEAD_TYPE_ENUM.LOGISTICS.value) {
          city = city + parseInt(child.deduction_amt);
        }
        // 供应商
        if (child.head_type === HEAD_TYPE_ENUM.SUPPLIER.value) {
          supplier = supplier + parseInt(child.deduction_amt);
        }
        this.selectIds.push(child.id);
      });
      total = city + supplier;
      this.formData = {
        total,
        city,
        supplier,
      };
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.ajaxPostQcRefundDeductionDone();
    },
    /**
     * 提交
     */
    async ajaxPostQcRefundDeductionDone() {
      this.loadingSubmit = true;
      try {
        const params = {
          entruck_detail_ids: this.selectIds,
        };
        await postQcRefundDeductionDone(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postQcRefundDeductionDone err", err);
      } finally {
        this.loadingSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 150px;
    .child {
      line-height: 40px;
      font-size: 16px;
      font-weight: 550;
    }
  }
  /deep/ .el-form {
    margin-left: 120px;
  }
  .el-input,
  .el-select {
    width: 400px;
  }
}
</style>
