var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "multipleTable",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
      on: { "selection-change": _vm.handleSelectionChange },
    },
    [
      _c("el-table-column", {
        attrs: {
          type: "selection",
          width: "55",
          selectable: _vm.selectAbleFirst,
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supplier_order_no",
          align: "center",
          label: "供货单号",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supply_date",
          align: "center",
          label: "供货日期",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "product_title",
          align: "center",
          label: "品名",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.product_title || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "category_name",
          align: "center",
          label: "品类",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sku_code",
          align: "center",
          label: "货号",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "buyer_name",
          align: "center",
          label: "采购员",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.buyer_name || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_name",
          align: "center",
          label: "城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.logistics_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "delivery_name",
          align: "center",
          label: "自提点",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.delivery_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "audit_status_txt",
          align: "center",
          label: "品控状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "review_user_name",
          align: "center",
          label: "品控员",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.review_user_name || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "qc_user_name",
          align: "center",
          label: "复核员",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.qc_user_name || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supplier_name",
          align: "center",
          label: "供应商",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.supplier_name || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "origin_number",
          align: "center",
          label: "采购数量",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "review_number",
          align: "center",
          label: "复核数量",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "stockout_number",
          align: "center",
          label: "缺货数量",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "qc_reason_txt",
          align: "center",
          label: "缺货原因",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.qc_reason_txt || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "head_type_txt",
          align: "center",
          label: "责任方",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.head_type_txt || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_amt",
          align: "center",
          label: "扣款金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_time",
          align: "center",
          label: "扣款时间",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_deduct_txt",
          align: "center",
          label: "扣款状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_coupon_txt",
          align: "center",
          label: "发券状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "操作", "min-width": "120", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.is_deduct === _vm.DEDUCT_STATUS.NO.value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _c("span", [_vm._v("-")]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }