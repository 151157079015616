<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form :model="formData" ref="refForm" :rules="formRules">
        <div class="details-warp">
          <div class="child">
            <div class="label">供应商：</div>
            <div class="value">{{ supplierName }}</div>
          </div>
          <div class="child">
            <div class="label">品名：</div>
            <div class="value">{{ productTitle }}</div>
          </div>
          <div class="child">
            <div class="label">缺货原因：</div>
            <div class="value">{{ reason }}</div>
          </div>
        </div>
        <el-form-item label="责任方：" prop="head_type">
          <el-select
            v-model="formData.head_type"
            placeholder="选择责任方"
            clearable
          >
            <el-option
              v-for="item of headTypeSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="扣款金额：" prop="deduction_amt">
          <el-input
            v-model="formData.deduction_amt"
            placeholder="输入扣款金额"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { validMoney } from "@/utils/tools/validate";
import {
  HEAD_TYPE_ENUM,
  STOCK_OUT_REASON_SELECT,
} from "../../utils/enum/index";
import { postQcRefundDeductionUpdate } from "@/api/general/operation-center/index";

export default {
  name: "EditDataDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loadingSelect: false,
      titleDialog: "编辑",
      headTypeSelect: Object.values(HEAD_TYPE_ENUM).filter(
        (child) => child.value !== HEAD_TYPE_ENUM.DEFAULT.value
      ), // 责任方下拉
      supplierName: "", // 供应商名称
      productTitle: "", // 品名
      reason: "", // 缺货原因
      formData: {
        entruck_detail_id: "", // 数据ID
        head_type: "", // 责任方
        deduction_amt: 0, // 扣款金额
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      formRules: {
        head_type: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择责任方",
          },
        ],
        deduction_amt: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value || !validMoney(value)) {
                callback(new Error("金额需大于等于0，允许两位小数点"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    async onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      data && this.setEditData(data);
    },

    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 回显数据
     */
    setEditData(data) {
      this.formData.entruck_detail_id = data.id;
      this.formData.head_type = data.head_type || "";
      this.formData.deduction_amt = data.deduction_amt;
      this.supplierName = data.supplier_name;
      this.productTitle = data.product_title;
      /** 当缺货原因为其他时，则展示缺货描述 */
      this.reason =
        data.qc_reason ===
        STOCK_OUT_REASON_SELECT[STOCK_OUT_REASON_SELECT.length - 1].id
          ? data.qc_reason_desc
          : data.qc_reason_txt;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxPostQcRefundDeductionUpdate();
      });
    },
    /**
     * 提交
     */
    async ajaxPostQcRefundDeductionUpdate() {
      try {
        const params = {
          ...this.formData,
        };
        params.sort = params.sort;
        await postQcRefundDeductionUpdate(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postQcRefundDeductionUpdate err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  /deep/ .el-form {
    margin-left: 50px;
    &-item__label {
      width: 150px;
    }
  }
  .el-input,
  .el-select {
    width: 400px;
  }
}
.details-warp {
  padding-bottom: 10px;
  .child {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    line-height: 40px;
    font-size: 14px;
    .label {
      width: 140px;
      padding-right: 10px;
      text-align: right;
      font-weight: 550;
    }
    .value {
      width: 400px;
    }
  }
}
</style>
