var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: { model: _vm.formData, rules: _vm.formRules },
            },
            [
              _c("div", { staticClass: "details-warp" }, [
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("供应商：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.supplierName)),
                  ]),
                ]),
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("品名：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.productTitle)),
                  ]),
                ]),
                _c("div", { staticClass: "child" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("缺货原因：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.reason)),
                  ]),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "责任方：", prop: "head_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择责任方", clearable: "" },
                      model: {
                        value: _vm.formData.head_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "head_type", $$v)
                        },
                        expression: "formData.head_type",
                      },
                    },
                    _vm._l(_vm.headTypeSelect, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "扣款金额：", prop: "deduction_amt" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入扣款金额", clearable: "" },
                    model: {
                      value: _vm.formData.deduction_amt,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "deduction_amt", $$v)
                      },
                      expression: "formData.deduction_amt",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }