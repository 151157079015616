/** 扣款状态 */
export const DEDUCT_STATUS = {
  NO: {
    value: 0,
    label: '否'
  },
  YES: {
    value: 1,
    label: '是'
  }
}

/** 发券状态 */
export const COUPON_STATUS = {
  NO: {
    value: 0,
    label: '否'
  },
  YES: {
    value: 1,
    label: '是'
  }
}

/** 缺货原因枚举 */
export const STOCK_OUT_REASON_SELECT = [
  {
    id: 2,
    name: "缺货"
  },
  {
    id: 3,
    name: "车已满"
  },
  {
    id: 4,
    name: "未开仓"
  },
  {
    id: 7,
    name: "破损"
  },
  {
    id: 8,
    name: "车晚到"
  },
  {
    id: 1,
    name: "未按时间送达"
  },
  {
    id: 9,
    name: "城市仓退货"
  },
  {
    id: 10,
    name: "供应商退货"
  },
  {
    id: 99,
    name: "其他"
  },
];

/** 责任方枚举 */
export const HEAD_TYPE_ENUM = {
  DEFAULT: {
    value: 0,
    label: '未指定'
  },
  SUPPLIER: {
    value: 1,
    label: '供应商'
  },
  LOGISTICS: {
    value: 2,
    label: '城市仓'
  }
}
